export default [
  {
    title: 'Task List',
    route: 'TaskList',
    icon: 'PackageIcon',
    user_types: ['all']
  },
  {
    title: 'All Tasks List',
    route: 'AdminTasksList',
    icon: 'PackageIcon',
    user_types: ['all']
  },
]